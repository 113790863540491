/* eslint-disable-next no-shadow */
export const CHECKOUT_WIDGET_ID = '14fd5970-8072-c276-1246-058b79e70c1a';

export enum FedopsInteractions {
  ApplyCouponInteraction = 'apply-coupon-interaction',
  ApplyGiftCardInteraction = 'apply-gift-card-interaction',
  PlaceOrderInteraction = 'place-order-interaction',
  ValidatePaymentInteraction = 'validate-payment-interaction',
  InitializePaymentInPlaceOrderInteraction = 'initialize-payment-in-place-order-interaction',
  ChangeDeliveryMethodInteraction = 'change-delivery-method-interaction',
  SubmitCustomerDetailsInteraction = 'submit-customer-details-interaction',
  InitFormsInteraction = 'init-forms-interaction',
  FetchCheckout = 'fetch-checkout',
}

export const SPECS = {
  CheckoutOOIThunderboltOptimization: 'specs.stores.CheckoutOOIThunderboltOptimization',
  AddSlotToCheckout: 'specs.stores.AddSlotToCheckout',
  DeliveryOverShippingText: 'specs.stores.deliveryOverShippingText',
  AddUnderTheSummarySlotToCheckout: 'specs.stores.AddUnderTheSummarySlotToCheckout',
  AllowCheckoutOOIForAnyFlow: 'specs.stores.AllowCheckoutOOIForAnyFlow',
  DontRedirectToSiteOnFailedFetch: 'specs.stores.DontRedirectToSiteOnFailedFetch',
  ShowLoaderWhileLoadingNewCheckout: 'specs.stores.ShowLoaderWhileLoadingNewCheckout',
  UseNewCheckoutInVisitorPickup: 'specs.stores.UseNewCheckoutInVisitorPickup',
  HeadlessNavigationCheckout: 'specs.stores.HeadlessNavigationCheckout',
  UseNewCheckoutInRestOfTheFlows: 'specs.stores.UseNewCheckoutInRestOfTheFlows',
  UseNewCheckoutInVisitorShippingAndDigitalFlows: 'specs.stores.UseNewCheckoutInVisitorShippingAndDigitalFlows',
  CheckoutExcludeGoogleAutoComplete: 'specs.stores.CheckoutExcludeGoogleAutoComplete',
  UseCashierConfirmPaymentFnForFastFlow: 'specs.stores.UseCashierConfirmPaymentFnForFastFlow',
  UseNewCheckoutForNonStoresFlows: 'specs.stores.UseNewCheckoutForNonStoresFlows',
  DisplayViolations: 'specs.stores.DisplayViolations',
  FetchDataExtendedCheckout: 'specs.stores.FetchDataExtendedCheckout',
  SkipDeliveryMethodStepForPickupFlow: 'specs.stores.SkipDeliveryMethodStepForPickupFlow',
  UseDataExtendedCheckout: 'specs.stores.UseDataExtendedCheckout',
};

export enum FieldMasks {
  customField = 'customFields',
  extendedFields = 'extendedFields',
  buyerInfoEmail = 'buyerInfo.email',
  shippingContact = 'shippingInfo.shippingDestination.contactDetails',
  shippingAddress = 'shippingInfo.shippingDestination.address',
  shippingAddressesServiceId = 'shippingInfo.shippingDestination.addressesServiceId',
  billingContact = 'billingInfo.contactDetails',
  billingAddress = 'billingInfo.address',
  billingAddressesServiceId = 'billingInfo.addressesServiceId',
  selectedCarrierServiceOption = 'shippingInfo.selectedCarrierServiceOption',
}

export enum AddressesFieldMasks {
  firstName = 'fullName.firstName',
  lastName = 'fullName.lastName',
  company = 'company',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  city = 'city',
  country = 'country',
  subdivision = 'subdivision',
  zipCode = 'zipCode',
  phoneNumber = 'phoneNumber',
  setAsDefault = 'setAsDefault',
  taxId = 'taxInfo.id',
  taxType = 'taxInfo.type',
  streetName = 'street.name',
  streetNumber = 'street.number',
}

export const FIELDS_GAP = 20;

export const MAX_ITEM_OPTION_LENGTH = 500;

export const THUMBNAIL_WIDTH = 60;

export const DEFAULT_IMAGE_ICON_SIZE = 33;

export const NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE = 0;

export const DELIVERY_METHOD_GROUP_NAME = 'deliveryOptions';

export const MAX_CUSTOM_FIELD_VALUE_LENGTH = 250;
export const MAX_ZIP_CODE_FIELD_LENGTH = 250;

export const PAYPAL = 'PayPal';

export const FORMS_TEMPLATE_IDS = {
  EMAIL: 'b739c372-940c-4148-8d13-9f139a662fa2',
  CONTACT: '5c1d74e6-ccaa-4e2f-9f9f-fb620ef73446',
  ADDITIONAL_INFO: 'afb63805-2e62-495e-969c-e45191af3818',
  ADDRESS: '7ad929e4-8c97-4259-80ba-1396cb8f3fca',
  VAT_ID: '90553971-6055-4a40-a746-75c8996dfe36',
  //TODO: need to change when prod from template ready
  EXTENDED_FIELDS_FORM_ID: '857b25f6-4c03-40a3-a948-27b6be630493',
};

export const FORMS_CHECKOUT_NAMESPACE = 'wix.ecom.v1.checkout';

export const DEFAULT_COUNTRY = 'US';

export const COUNTRIES_WITH_VAT = ['BR'];

export const ADD_NEW_ADDRESS_ID = 'addNewAddress';
