import {CheckoutModel} from '../domain/models/Checkout.model';
import {CheckoutErrorModel} from '../domain/models/CheckoutError.model';
import {CheckoutSettingsModel} from '../domain/models/checkoutSettings/CheckoutSettings.model';
import {PolicyButtonLocation, PolicyType} from '../components/Checkout/PolicyButtonWithDialog/PolicyButtonWithDialog';
import {ITrackEventName, ITrackEventParams} from '@wix/native-components-infra';
import {SlotId} from '../domain/utils/slotId';
import {PaymentsWidgetConfiguration} from '@wix/cashier-payments-widget/dist/src/types/PaymentsWidgetConfiguration';
import {PaymentsWidgetProps} from '@wix/cashier-payments-widget/dist/src/types/PaymentsWidgetProps';
import {MemberAddressesInfoModel} from '../domain/models/MemberAddressesInfo.model';
import {PaymentMethod} from '@wix/cashier-payments-widget';
import {PaymentError} from './payment.types';
import {
  ApiAddressFragment,
  CheckoutFragment,
  CheckoutWithExtendedFieldsFragment,
  FullAddressContactDetailsFragment,
} from '../gql/graphql';
import {AddressWithContactModel} from '../domain/models/AddressWithContact.model';
import {BiAddressActionOrigin} from '../domain/utils/bi.util';
import {ViolationModel} from '../domain/models/Violation.model';

export declare type OnlyFunctionProps<O> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof O]: O[K] extends Function ? K : never;
}[keyof O];

export type ControllerProps = {
  isLoading: boolean;
  failedToFetch?: boolean;
  checkoutStore: CheckoutStoreProps;
  deliveryMethodStore: DeliveryMethodStoreProps;
  checkoutSettingsStore: CheckoutSettingsStoreProps;
  navigationStore: NavigationStoreProps;
  slotsStore: SlotStoreProps;
  checkboxesStore: CheckboxesStoreProps;
  stepsManagerStore: StepsManagerStoreProps;
  paymentStore: PaymentStoreProps;
  formsStore: FormsStoreProps;
  memberStore: MemberStoreProps;
};

export interface CheckoutStoreProps {
  checkout: CheckoutModel;
  placeOrderError?: CheckoutErrorModel;
  setPlaceOrderPaymentError: (paymentError: PaymentError, errorCode: string) => void;
  applyCouponError?: CheckoutErrorModel;
  applyGiftCardError?: CheckoutErrorModel;
  updateCheckoutError?: CheckoutErrorModel;

  isPlaceOrderButtonDisabled: boolean;
  shouldRequireZipCode: boolean;
  shouldShowSubdivisionSelector: boolean;
  isFastFlow: boolean;
  isPickupFlow: boolean;
  isShippingFlow: boolean;
  applyCoupon: (couponCode: string) => Promise<void>;
  removeCoupon: () => Promise<void>;
  applyGiftCard: (giftCardCode: string) => Promise<void>;
  removeGiftCard: () => Promise<void>;
  setCustomField: (customFieldValue: string) => Promise<void>;
  removeLineItem: (lineItemId: string) => Promise<void>;
  isMissingCustomField: boolean;
  clickPlaceOrderButton: (
    shouldSubscribe: boolean,
    detailsId?: string
  ) => Promise<
    | {
        orderId?: string | null;
        subscriptionId?: string;
        paymentResponseToken?: string | null;
        paymentError?: PaymentError;
      }
    | undefined
  >;
  submitCustomerDetails: ({
    contactDetails,
    email,
    customFieldValue,
    extendedFieldsValue,
    shippingAddress,
    addressesServiceId,
    setAsDefault,
  }: {
    contactDetails?: FullAddressContactDetailsFragment;
    email?: string;
    customFieldValue?: string;
    shippingAddress?: ApiAddressFragment;
    addressesServiceId?: string;
    extendedFieldsValue?: any;
    setAsDefault?: boolean;
  }) => Promise<void>;
  onDeliveryMethodContinue: () => void;
  setPaymentAndBillingDetails: ({
    contactDetails,
    address,
    addressesServiceId,
    setBillingSameAsShipping,
    setAsDefault,
    activePaymentId,
  }: {
    contactDetails?: FullAddressContactDetailsFragment;
    address?: ApiAddressFragment;
    addressesServiceId?: string;
    setBillingSameAsShipping: boolean;
    setAsDefault?: boolean;
    activePaymentId?: string;
  }) => Promise<void>;
  setZipCode: (zipCodeValue: string) => Promise<void>;
  onAddCouponSectionOpen: () => void;
  onAddGiftCardSectionOpen: () => void;
  onErrorDialogClosed: () => void;
  onErrorDialogOpened: () => void;
  onPolicyClicked: (linkLocation: PolicyButtonLocation, policyType: PolicyType) => void;
  setSubdivision: (subdivisionValue: string) => Promise<void>;
  shouldShowGiftCardSection: boolean;
  shouldShowCouponSection: boolean;
  onLogoutDialogToggled: (isOpen: boolean) => void;
  shouldShowViolations: boolean;
  topThreeViolations: ViolationModel[];
}

export interface PaymentStoreProps {
  cashierWidgetProps: PaymentsWidgetProps;
  cashierConfiguration: PaymentsWidgetConfiguration;
  cashierMandatoryFields: CashierMandatoryFieldsOverrides;
  confirmPayment: (arg: {chargeResponseToken: string}) => Promise<void>;
  setCashierMandatoryFields: (cashierMandatoryFields: CashierMandatoryFieldsOverrides) => void;
}

export interface FormsStoreProps {
  areFormsLoaded: boolean;
  validateBillingAddress: (addressWithContact: AddressWithContactModel) => Promise<boolean>;
  validateShippingAddress: (addressWithContact: AddressWithContactModel) => Promise<boolean>;
}

export interface MemberAddressActionBiParams {
  checkout: CheckoutModel;
  origin: BiAddressActionOrigin;
  stage: string;
  addressServiceId?: string;
}
export interface MemberStoreProps {
  isMember: boolean;
  addressesInfo: MemberAddressesInfoModel;
  defaultAddressId?: string;
  isAddressesAppInstalled: boolean;
  currentUserEmail: string;
  reportEditAddressClicked: (params: MemberAddressActionBiParams) => void;
  reportAddAddressClicked: (params: MemberAddressActionBiParams) => void;
}

export interface CheckboxesStoreProps {
  isPolicyCheckboxChecked: boolean;
  toggleIsPolicyCheckboxChecked: (checked: boolean) => void;
  isDigitalPolicyCheckboxChecked: boolean;
  toggleIsDigitalPolicyCheckboxChecked: (checked: boolean) => void;
  isSubscriptionCheckboxChecked: boolean;
  toggleIsSubscriptionCheckboxChecked: (checked: boolean) => void;
  areCheckoutCheckboxesValid: boolean;
  wasFormSubmitted: boolean;
  setWasFormSubmitted: (wasSubmitted: boolean) => void;
  shouldShowCheckboxesSection: boolean;
}

export interface StepsManagerStoreProps {
  updateStepOnStage: (stepIndex: number, stepId: StepId) => void;
  openStep: (stepIndex: number) => void;
  sendEditStepClickedBIEvent: (stepId: StepId, previousStepName?: StepId) => void;
  shouldDisplayExpressCheckout: boolean;
  activeStep: ActiveStep;
  stepsList: CheckoutStep[];
}

export interface DeliveryMethodStoreProps {
  isSelectedShippingOptionPickup: boolean;
  canShipToDestination: boolean;
  setShippingOption: (shippingOptionId: string) => Promise<void>;
  hasShippingOptions: boolean;
  isUpdatingShippingOption: boolean;
  beforePlaceOrderClicked: () => void;
}

export interface NavigationStoreProps {
  clickOnBackToSite: () => void;
  clickOnContinueShopping: () => void;
  clickOnReturnToCart: () => Promise<void>;
  clickOnEditCart: () => void;
  navigateToThankYouPage: (params: navigateToThankYouPageParams) => Promise<void> | void;
  continueShoppingUrl: string;
  editCartUrl: string;
  trackEvent: (eventName: ITrackEventName, eventProps: ITrackEventParams) => void;
  onLogin: () => Promise<void>;
  onLogout: () => Promise<void>;
  isSSR: boolean;
}

export interface CheckoutSettingsStoreProps {
  checkoutSettings: CheckoutSettingsModel;
  showCouponSP: boolean;
}

export interface SlotStoreProps {
  setSlotsParams: (slotId: SlotId) => void;
  setStepId: (slotId: SlotId, stepId: StepId | null) => void;
  setPaymentId: (slotId: SlotId, paymentId?: PaymentMethod) => void;
}

export type PlaceOrderUrlParams = {
  errorUrl?: string;
  cancelUrl?: string;
  successUrl?: string;
  pendingUrl?: string;
};

export type navigateToThankYouPageParams = {
  orderId?: string;
  isSubscription: boolean;
};

export enum CashierMandatoryField {
  street = 'street',
  houseNumber = 'houseNumber',
  address = 'address',
  city = 'city',
  countryCode = 'countryCode',
  state = 'state',
  zipCode = 'zipCode',
  phone = 'phone',
}

export type CashierMandatoryFieldsOverrides = {
  [key in CashierMandatoryField]?: boolean;
};

export enum StepState {
  EMPTY = 'empty',
  OPEN = 'open',
  COLLAPSED = 'collapsed',
}

export type CheckoutStep = {
  id: StepId;
  state: StepState;
};

export type StepImplementationProps = {
  index?: number;
};

export enum StepId {
  customerDetails = 'contact-details',
  deliveryMethod = 'delivery-method',
  payment = 'payment-and-billing',
  placeOrder = 'place-order',
}

export type ActiveStep = {
  stepId: StepId;
  stepIndex: number;
};

export enum ViolationsAndPlaceOrderButtonLocation {
  summary = 'summary',
  placeOrderStep = 'placeOrderStep',
  fastFlow = 'fastFlow',
}

export type Checkout = CheckoutFragment | CheckoutWithExtendedFieldsFragment;
